<template>
  <div id="employee-new-modal">
    <vs-popup class="popup90" :title="title" :active.sync="localShow">

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-0">
          <label class="vs-input--label">Tipo de Pessoa</label>
          <v-select v-model="provider.type" :reduce="option => option.value" :clearable="false"
            :options="[
              {label: 'Física', value: 'FISICA'},
              {label: 'Jurídica', value: 'JURIDICA'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-2/5 w-full mt-0">
          <vs-input class="w-full mt-0" label="Nome/Razão Social" v-model="provider.name" data-vv-scope="provider" data-vv-as="Nome/Razão Social" v-validate.initial="'required'" name="name" />
          <span class="text-danger text-sm" v-show="errors.has('provider.name')">{{ errors.first('provider.name') }}</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input class="w-full" label="CNPJ/CPF" v-model="provider.cpf" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-scope="provider" data-vv-as="CPF" v-validate="'cpf'" name="cpf" />
          <span class="text-danger text-sm" v-show="errors.has('provider.cpf')">{{ errors.first('provider.cpf') }}</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-0">
          <vs-input class="w-full" label="IE/RG" v-model="provider.rg" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full" v-for="(phone,i) in provider.phones" :key="i">
          <vs-input class="w-full mt-2" v-mask="['(##)#####-####','(##)####-####']" :label="phone.type === 'phone' ? 'Telefone' : phone.type === 'mobile' ? 'Celular' : phone.type === 'other' ? 'Outro' : ''" v-model="phone.phone" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="E-mail" data-vv-as="E-mail" v-model="provider.email" data-vv-scope="provider" type="email" v-validate="'email'" name="email" />
          <span class="text-danger text-sm" v-show="errors.has('provider.email')">{{ errors.first('provider.email') }}</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Plano de contas</label>
          <v-select class="vue_select_drop_size_180" v-model="provider.account_plan_id"
            data-vv-as="Plano de Contas" data-vv-scope="provider" v-validate.initial="'required'" name="account_plan_id"
            :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('provider.account_plan_id')">Campo Obrigatório</span>
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Endereço</span>
      </div>
      <hr>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="provider.address.zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Logradouro</label>
          <v-select v-model="provider.address.logradouro" :reduce="option => option.value" clearable :options="logradouroOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="Endereço" v-model="provider.address.street" />
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input class="w-full" label="Número" v-model="provider.address.number" type="number" placeholder="S/N" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Complemento" v-model="provider.address.complement" />
        </div>
        <div class="vx-col md:w-1/5 w-full">
          <vs-input class="w-full mt-2" label="Bairro" v-model="provider.address.neighborhood" />
        </div>
        <div class="vx-col md:w-1/4_ w-full mt-2">
          <label class="vs-input--label">Cidade</label>
          <v-select v-model="provider.address.city_id" :reduce="option => option.value" :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">Estado</label>
          <v-select v-model="provider.address.state_id" :reduce="option => option.value" @input="fillCity(provider.address.state_id), provider.address.city_id = null" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-2">
          <label class="vs-input--label">País</label>
          <v-select v-model="provider.address.country" :reduce="option => option.value" :clearable="false" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save" :disabled="!validateForm">Alterar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import moduleStates from '@/store/state/moduleStates.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'

export default {
  components: {
    vSelect
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    actionButtonText: {
      String, default: 'Deletar'
    },
    actionButtonColor: {
      String, default: 'danger'
    }
  },

  computed: {
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterDespesa']
    },
    states () {
      return this.$store.getters['states/getStates']
    },
    validateForm () {
      return !this.errors.any()
    }
  },

  data () {
    return {
      localShow: false,
      provider: {
        type: 'JURIDICA',
        name: null,
        cpf: null,
        rg: null,
        phones: [
          {type: 'mobile', phone: null},
          {type: 'phone', phone: null},
          {type: 'other', phone: null}
        ],
        email: null,
        account_plan_id: null,
        address: {
          type_address: 'COM',
          zip_code: null,
          logradouro: null,
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          city_id: null,
          state_id: null,
          country: 1 //Brasil
        }
      },

      countryOptions: [{ label: 'Brasil', value: 1 }],

      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' }
      ],

      cityOptions:[]
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.provider = {
          type: 'JURIDICA',
          name: null,
          cpf: null,
          rg: null,
          phones: [
            {type: 'mobile', phone: null},
            {type: 'phone', phone: null},
            {type: 'other', phone: null}
          ],
          email: null,
          account_plan_id: null,
          address: {
            type_address: 'COM',
            zip_code: null,
            logradouro: null,
            street: null,
            number: null,
            complement: null,
            neighborhood: null,
            city_id: null,
            state_id: null,
            country: 1 //Brasil
          }
        }
        this.$emit('cancel')
      } else {
        this.$store.dispatch('accountPlan/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.provider = JSON.parse(JSON.stringify(this.$store.getters['provider/get']))
        if (this.provider.address.state_id) {
          if (this.provider.address.city_id) {
            this.fillCity(this.provider.address.state_id, this.provider.address.city_id)
          } else {
            this.fillCity(this.provider.address.state_id)
          }
        }
      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('provider/update', this.provider)
        this.$vs.loading.close()
        this.action()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    async searchZipCode ($event) {
      const zip_code = this.provider.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        this.$http._noHeaders = true //enviando um get sem Autorization no header
        const resp = await this.$http.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.provider.address.neighborhood = resp.data.bairro
        this.provider.address.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.provider.address.street = resp.data.logradouro
        }

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.provider.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade)
      }
    },

    async fillCity (stateId, city = null) {
      this.cityOptions = []
      try {
        if (stateId) {
          const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          // this.cityOptions.push({ value: null, label: 'Selecione' })

          for (const k in resp.data) {
            this.cityOptions.push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this.cityOptions.find(x => x.value === city)
            if (!ct) {
              ct = this.cityOptions.find(x => x.label === city)
            }
            this.provider.address.city_id = ct.value
          }
        }
      } catch (error) { console.error(error) }
    }
  },
  created () {
    if (!moduleStates.isRegistered) {
      this.$store.registerModule('states', moduleStates)
      moduleStates.isRegistered = true
    }
    this.$store.dispatch('states/fetchStates')

    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }

  .vue_select_drop_size_200 .vs__dropdown-menu {
    max-height: 200px;
  }
</style>
